body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
	--brown: #232221;
	--white: #ffffff;
	--dark-blue: #053d6a;
	--light-blue: #12b3dd;
	--orange: #efa70c;
	--red: #bd4e40;
	--text-color: #e5cd9e;
	--pink: #d65f6a;
}

#root {
	background-color: var(--brown);
	font-family: PlusJakartaSans;
}

@font-face {
	font-family: "PlusJakartaSans";
	src: local("PlusJakartaSans"),
		url("./fonts/PlusJakartaSans/PlusJakartaSans-Regular.woff") format("truetype");
	/* font-weight: bold; */
}

@font-face {
	font-family: "PlusJakartaSans-Bold";
	src: local("PlusJakartaSans-Bold"),
		url("./fonts/PlusJakartaSans/PlusJakartaSans-Bold.woff") format("truetype");
	/* font-weight: bold; */
}

@font-face {
	font-family: "PlusJakartaSans-Medium";
	src: local("PlusJakartaSans-Medium"),
		url("./fonts/PlusJakartaSans/PlusJakartaSans-Medium.woff") format("truetype");
	/* font-weight: bold; */
}

h1 {
	font-family: PlusJakartaSans-Medium;
}

html {
	/* background-color: var(--dark-blue); */
}

/****************************Universal CSS Classes***********************/

.flex-wrapper {
	display: flex;
	display: -webkit-flex;
	display: -ms-flexbox;
	justify-content: center;
	-webkit-justify-content: center;
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}

.flex-content {
	flex: 3;
	-webkit-flex: 3;
	-ms-flex: 3 0 auto;
}

.flex {
	display: flex;
	display: -webkit-flex;
	display: -ms-flexbox;
}

.flex-vertical {
	display: flex;
	display: -webkit-flex;
	display: -ms-flexbox;
	flex-direction: column;
	-webkit-flex-direction: column;
	-ms-flex-direction: column;
}

.flex-one {
	flex: 1 0 auto;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
}

.flex-one-same {
	flex: 1 1 0px;
}

.flex-wrap {
	flex-wrap: wrap;
	-webkit-flex-wrap: wrap;
}

.flex-center {
	align-items: center;
	-webkit-align-items: center;
	-ms-align-items: center;
	justify-content: center;
	-webkit-justify-content: center;
	-ms-justify-content: center;
}

.center {
	text-align: center;
	margin: auto;
	justify-content: center;
	flex-direction: column;
}

.flex-reverse {
	flex-direction: row-reverse;
	-webkit-flex-direction: row-reverse;
}

.hide {
	display: none !important;
}

.no-margin {
	margin: 0;
}

/*End Universal CSS Classes*/

.map-container {
	/* width: 60%;
  margin: auto; */
	flex-wrap: wrap;
	justify-content: center;
}

.select-team-container {
	/* width: 100%; */
	justify-content: center;
	padding: 15px 25px 0;
	background-color: var(--dark-blue);
}

.react-select-team-container {
	width: 350px;
	margin: auto 0 auto 10px;
}

.react-select-team__menu {
	z-index: 100 !important;
}

.select-team-label {
	align-self: center;
	margin-right: 10px;
	color: white;
	margin-left: auto;
}

.select-team {
	align-self: center;
}

.map-container-maps {
	-webkit-flex-wrap: wrap;
	flex-wrap: wrap;
	margin: auto;
	justify-content: center;
	width: 100%;
	overflow: auto;
	/* background-color: var(--dark-blue); */
}

.utility-round-container {
	background-color: var(--dark-blue);
	color: white;
	padding-bottom: 50px;
}

.utility-round-container .map-container .map-svg-container {
	margin: 5px;
}

.round-specific-container .map-container .map-svg-container {
	max-width: 40%;
	min-width: 40%;
	margin: 5px auto;
}

.next-prev-buttons-container {
	width: 650px;
	flex-wrap: wrap;
	margin: auto;
}

.next-prev-buttons {
	background-color: var(--orange);
	color: black;
	cursor: pointer;
	min-width: 50px;
	max-width: 50px;
	height: 40px;
	margin: 0 1px;
	border: 1px solid white;
	align-items: center;
	justify-content: center;
}

.selected-prev-next-button {
	background-color: var(--red);
	color: white;
}

.prev-round-button {
	margin-right: auto;
}

.next-round-button {
	margin-left: auto;
}

.weapon-used-container {
	/* margin: auto; */
	border: 1px solid;
	border-bottom: 0;
	flex-wrap: wrap;
}

.weapon-used {
	padding: 5px;
	font-size: .75rem;
	flex: 1 1 0;
	-webkit-flex: 1 1 0;
	-ms-flex: 1 1 0;
	white-space: nowrap;
	overflow: hidden;
	color: white;
	border-bottom: 1px solid;
	min-width: 15%;
	min-height: 30px;
}

.map-svg-container {
	/* max-height: 100%; */
	margin: 1%;
	/* cursor: pointer; */
	flex: 1;
	-webkit-flex: 1;
}

.maps-per-row-1 {
	min-width: 50%;
	max-width: 50%;
}

.maps-per-row-2 {
	min-width: 40%;
	max-width: 40%;
}

.maps-per-row-3 {
	min-width: 30%;
	max-width: 30%;
}

.maps-per-row-4 {
	min-width: 20%;
	max-width: 20%;
}

.maps-per-row-5 {
	min-width: 15%;
	max-width: 15%;
}

.map-expand-round {
	margin: 10px auto 0;
	padding: 10px 10px;
	background: var(--pink);
	border-radius: 5px;
	width: auto;
	cursor: pointer;
	letter-spacing: .5px;
	font-size: 1.05rem;
}

/* .map-svg-container:has(.map-modal) {
	Doesn't work rn b/c it doesn't put window right where you click, out of order
	cursor: pointer;
	max-width: 70%;
	width: 70%;
	min-width: 70%;
} */

svg circle:hover {
	/* cursor: pointer; */
	/* stroke-width: 8px; */
}

svg .utility-image:hover {
	cursor: pointer;
}

svg:hover {
	cursor: default;
}

.svg-font-names-single {
	font-size: 75px;
}

.c4-image {
	filter: invert(59%) sepia(80%) saturate(977%) hue-rotate(1deg) brightness(105%) contrast(107%);
}

.c4-image-planted {
	filter: invert(35%) sepia(97%) saturate(6701%) hue-rotate(353deg) brightness(96%) contrast(127%);
}

.ct-utility {
	filter: drop-shadow(0 0 0.5rem rgb(18, 179, 221));
}

.t-utility {
	filter: drop-shadow(0 0 0.5rem rgb(255, 255, 0));
}

.slider {
	width: 400px;
	height: 20px;
	-webkit-appearance: none;
	background: #fff;
	outline: none;
	border-radius: 15px;
	overflow: hidden;
	box-shadow: inset 0 0 5px rgba(255, 255, 255, 1);
	margin: auto;
}

#map-slide {
	width: 200px;
}

.slider::-webkit-slider-thumb {
	-webkit-appearance: none;
	width: 16px;
	height: 16px;
	border-radius: 50%;
	background: #3661eb;
	cursor: pointer;
	border: 1px solid var(--dark-blue);
	/* box-shadow: -407px 0 0 400px #00fd0a; */
}

.slider::-moz-range-thumb {
	background: #3661eb;
	cursor: pointer;
	border: 1px solid var(--dark-blue);
}

.slider-label {
	font-size: .9rem;
	margin-bottom: 5px;
	display: inline-block;
}

.select-matches-container {
	justify-content: center;
	padding: 10px;

}

.react-select-matches-container {
	width: 700px;
	color: black;
	text-align: left;
	padding: 0 10px;
}

.react-select-matches__multi-value {
	background-color: var(--orange) !important;

}

.react-select-matches__value-container {
	flex-wrap: nowrap !important;
	-webkit-flex-wrap: nowrap;
}

.react-select-matches__indicator, .react-select-matches__indicator, .react-select-team__indicator, .react-select-team__indicator {
	color: darkgray !important;
}

.react-select-matches__indicator-separator, .react-select-team__indicator-separator {
	background-color: darkgray !important;
}

.react-select-matches__control {
	border-color: var(--light-blue) !important;
}

.react-select-matches__menu {
	display: block !important;
}

.react-select-matches__option--is-selected {
	background-color: var(--light-blue) !important;
}

.react-select-matches__option--is-disabled {
	color: darkgray !important;
}

/* Roster/General Info section */
.roster-container {
	justify-content: center;
	align-items: center;
}

.scouting-container {
	height: 100vh;
}

.scouting-header {
	background: white;
	color: black;
	padding: 3px;
	text-align: center;
	width: 100%;
}

/* Settings Sidebar */

.settings-container {
	height: 100%;
	width: 210px;
	position: fixed;
	z-index: 1;
	top: 0;
	left: 0;
	align-items: flex-start;
	background-color: var(--brown);
	text-align: left;
	color: #fff;
	transition: all 0.5s;
	display: none;
}

.settings-container-collapsed {
	width: 10px;
	padding: 0;
	transition: all 0.5s;
}

.settings-items-container {
	overflow-x: hidden;
	width: 100%;
	transition: all 0.5s;
	padding: 15px 5px;
	box-sizing: border-box;
	height: 100%;
}

.settings-items-container-collapsed {
	visibility: collapse;
	opacity: 0;
	transition: all 0.5s;
}

.settings-header {
	margin: 0 auto;
}

.settings-hr {
	border-bottom: 1px solid lightgray;
	width: 80%;
	margin: 15px auto;
}

.settings-hr-first {
	margin-top: 5px;
}

.settings-select-container div {
	/* margin: 10px auto; */
}

.settings-collapse-arrow {
	position: absolute;
	right: -15px;
	top: 40px;
	width: 32px;
	height: 32px;
	border-radius: 50%;
	background: var(--orange);
	line-height: 28px;
	font-weight: bold;
	text-align: center;
	cursor: pointer;
	color: white;
	font-size: 1.25rem;
}

.nav-title {
	width: 80px;
	margin: 0 auto 0 0;
}

.setting-logo {
	width: 100%;
	margin: 0 auto;
}

.setting-logo img {
	width: 100%;
	border-radius: 20px;
}

.setting-logo-home {
	height: 100%;
}

.setting-logo-home img {
	height: 100%;
}

.map-slide-container {
	margin: 0 20px;
	margin-bottom: 7.5px;
	align-items: center;
}

.map-slide-container select {
	padding: 4px;
	border-radius: 5px;
	margin-left: 5px;
}

.filter-button {
	padding: 10px 15px;
	background-color: var(--light-blue);
	border: 1px solid white;
	border-radius: 5px;
	margin: 0 5px;
	color: white;
	font-size: 1rem;
	font-weight: 500;
	letter-spacing: .5px;
}

.filter-button-checked {
	background-color: var(--red);
}

.select-player-header {
	height: 100px;
	padding: 10px;
	width: 90%;
	margin: auto;
}

.select-player-container {
	background-color: var(--brown);
}

.select-player-logo {
	height: 100%;
	width: 250px;
	text-align: left;
}

.select-player-logo img {
	height: 100%;
}

.time-slide-container {
	align-self: center;
	margin: auto;
	color: white;
	width: 100%;
	text-align: center;
	position: sticky;
	top: 0;
	padding: 10px 0;
	background-color: var(--dark-blue);
	z-index: 10;
}

.awp-mark {
	height: 20px;
	justify-content: center;
    margin-top: 5px;
}

.awp-input-pointer {
	cursor: pointer !important;
}

.play-pause-container {
	position: relative;
	display: inline-block;
}

.play-pause-button {
	position: absolute;
	flex: 1;
	left: -40px;
	top: 28px;
	fill: white;
	width: 30px;
}

/* .player-overview-slider {
	position: relative;
	position: sticky;
} */

.position-container {
	transition: all 0.5s;
	background-color: var(--dark-blue);
	color: white;
}

.old-roster-button button {
	padding: 5px 10px;
	border-radius: 5px;
	border: 0;
	background-color: var(--orange);
	margin: 10px auto 15px;
	font-family: PlusJakartaSans-Medium;
	cursor: pointer;
}

.home {
	/* margin-left: 210px; */
	text-align: center;
	/* font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif; */
	font-family: PlusJakartaSans-Medium;
	transition: all 0.5s;
}

.home-collapsed {
	margin-left: 10px;
	transition: all 0.5s;
}

.home-player {
	text-align: center;
	/* margin: 0 90px; */
}

/* Modal Window */
.modal-container {
	flex-wrap: wrap;
	height: 95%;
	text-align: center;
	margin-top: 25px;
}

.modal-container .map-svg-container {
	margin: auto;
}

.ReactModal__Content {
	background-color: var(--dark-blue) !important;
}

.modal-button {
	margin-right: 15px;
	padding: 10px 15px;
	background: lightgray;
	border-radius: 5px;
	cursor: pointer;
	width: 125px;
	align-self: center;
	text-align: center;
}

.modal-button-space {
	width: 155px;
}

.check-settings-container {
	justify-content: center;
}

.settings-input {
	margin: 0 20px;
}

.settings-select input {
	margin-right: 5px;
	padding: 5px;
	text-align: center;
	border-radius: 2px;
	border: 1px solid;
	font-size: 1rem;
	width: 40px;
	-moz-appearance: textfield;
}

.settings-input input {
	margin-right: 10px;
	height: 15px;
	width: 15px;
}

.settings-input, .settings-select {
	margin-bottom: 7.5px;
	cursor: pointer;
	align-items: center;
}

.settings-input label, .settings-input input {
	cursor: pointer;
}

.settings-input input[type=checkbox] {
	accent-color: var(--orange);
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
}

.ReactModal__Overlay {
	z-index: 2;
}

.map-name {
	cursor: pointer;
}

.map-container-map-names {
	justify-content: center;
}

.map-container-map-names h1:empty::after {
	content: ".";
	visibility: hidden;
}

.map-names-vertical {
	margin: auto;
	writing-mode: vertical-rl;
	text-orientation: mixed;
	transform: rotate(180deg);
	color: white;
}

.player-overview-container {
	width: 19%;
}

.player-overview-container h1 {
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	margin-top: 0;
}

.player-overview-container .map-svg-overview-container {
	width: 98%;
	max-width: 98%;
	margin: 20px 1%;
	flex: 1;
	-webkit-flex: 1;
}

.map-svg-overview-container-modal {
	height: 100%;
	max-height: 100%;
}

.map-svg-overview-container-modal svg {
	height: 100%;
	max-height: 100%;
}

.map-header-info {
	margin: 5px auto;
	font-size: .9rem;
}

.economy-slider-container {
	width: 400px;
	margin: auto;
	margin-top: 15px;
}

.economy-slider-min, .economy-slider-max {
	width: 100px;
}

.range-slider {
	margin: 7px;
}

.range-slider .range-slider__range {
	background: var(--light-blue) !important;
}

.range-slider .range-slider__thumb {
	background: var(--light-blue) !important;
	border: 1px solid black;
}

/* Stats page specific css */

.team-stats-container {
	text-align: left;
	margin: auto;
	width: 80%;
}

.radar-chart-container {
	justify-content: center;
}

.stats-graphs-container {
	flex-wrap: wrap;
	margin-top: 25px;
}

.recharts-text,
.recharts-line,
.recharts-cartesian-axis-tick-line {
	fill: white;
}

.recharts-cartesian-axis-label {
	fill: white;
}

.recharts-cartesian-grid-line {
	stroke: rgba(255, 255, 255, 0.2);
}

.recharts-tooltip-label {
	color: black;
}

.map-stats-container {
	margin-top: 15px;
	margin: auto;
	min-height: 300px;
	width: 50%;
	min-width: 500px;
}

.map-stats-label {
	margin-right: 10px;
}

.map-stats-upcoming {
	font-style: italic;
	font-size: .75rem;
	margin-bottom: 5px;
	text-align: center;
}

.map-stats-header {
	font-size: 1.5rem;
	font-weight: bold;
	margin-bottom: 5px;
	text-align: center;
}

.map-stats {
	margin-left: 25px;
	margin-bottom: 5px;
}

.roles-container {
	margin: auto;
	width: 80%;
	text-align: center;
	margin-bottom: 25px;
}

.roles-h1 {
	margin-top: 15px;
	font-size: 1.75rem;
	margin-bottom: 10px;
}

.role-cell {
	border: 1px solid;
	margin-right: -1px;
	margin-bottom: -1px;
	padding: 5px;
	height: 2.5rem;
    font-size: 1rem;
    align-items: center;
    justify-content: center;
}

.roles-header-row {
	font-weight: bold;
}

/* Player Comparison Specific Css */

.player-comps {
	background-color: var(--dark-blue);
	min-height: calc(100vh - 120px);
}

.player-comp-container {
	width: 50%;
	padding: 0 5%;
	margin: 25px auto;
}

.player-comp-container:first-child {
	border-right: 1px solid white;
}

.player-comp-container .map-container-maps {
	width: 100%;
}

.player-comp-container .map-container-maps .player-overview-container {
	width: 80%;
}

.react-select-player-container {
	width: 300px;
	align-self: center;
}

.player-nav-item {
	align-self: center;
	text-align: right;
	width: 250px;
	margin-right: 15px;
	margin-left: auto;
}

.player-nav-item a {
	color: black;
	font-size: 1.15rem;
	font-size: 1rem;
	padding: 15px 35px;
	background-color: var(--orange);
	border-radius: 5px;
	text-decoration: none;
	color: black;
}

/* React Tabs */
.map-tab-list {
	text-align: left;
	padding-left: 10px;
	border-color: #d4d4d5;
	border: none;
	padding: 5px;
	margin: 0;
}

.react-tabs__tab {
	padding: 12px 20px;
	font-family: Lato, 'Helvetica Neue', Arial, Helvetica, sans-serif;
	/* border-color: #d4d4d5; */
	border-radius: 3dpx;
	flex: 1 0 auto;
	-webkit-flex: 1 0 auto;
	-ms-flex: 1 0 auto;
	text-align: center;
	border: 1px solid var(--light-blue);
	letter-spacing: .75px;
	font-size: 1.05rem;
}

.react-tabs__tab--selected {
	font-weight: 500;
	/* border-color: #d4d4d5;
	border-radius: 3px 3px 0 0; */
	background-color: var(--light-blue);
	color: white;
	border: none;
}

.react-tabs__tab:focus:after {
	display: none;
}

/* Loading Ring */
.lds-ring-container {
	display: inline-block;
	/* position: relative;
	width: 80px;
	height: 80px; */
	margin: auto;
	width: 100%;
	height: 100%;
	position: fixed;
	background-color: rgba(0, 0, 0, 0.3);
	top: 0;
	left: 0;
	z-index: 1;
}

.lds-ring {
	right: 0;
	left: 0;
	position: absolute;
	top: 49%;
}

.lds-ring div {
	box-sizing: border-box;
	display: block;
	position: absolute;
	width: 64px;
	height: 64px;
	margin: 8px;
	border: 8px solid #FFF;
	border-radius: 50%;
	animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
	border-color: #FFF transparent transparent transparent;
	top: 49%;
	left: calc(50% - 50px);
}

.lds-ring div:nth-child(1) {
	animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
	animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
	animation-delay: -0.15s;
}

.loading-bar-container {
	width: 25%;
	height: 20px;
	margin: auto;
	background-color: white;
	border-radius: 20px;
	overflow: hidden;
	position: absolute;
	top: calc(49% + 100px);
	left: 0;
	right: 0;
	color: black;
}

.loading-bar-text {
	position: absolute;
	left: 0;
	right: 0;
}

.loading-bar-bar {
	height: 100%;
	background-color: var(--orange);
	color: black;
	transition: width 0.25s;
	border-radius: 25px;
}

.refresh-container {
	position: absolute;
	left: 0;
	right: 0;
	top: calc(50% + 125px);
}

.refresh-button {
	background-color: var(--red);
	padding: 5px 25px;
	border-radius: 5px;
	color: white;
	cursor: pointer;
}

@keyframes lds-ring {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}

/* End Loading Ring */

/* Landing Page */
.landing-page-container {
	min-height: 100vh;
}

.landing-page-container {
	width: 90%;
	margin: 0 5%;
	/* max-width: 1000px; */
	margin: auto;
	color: white;
	/* justify-content: center;
	align-items: center; */
}

.landing-main-container {
	max-width: 1250px;
	margin: auto;
	width: 100%;
	margin-top: 0;
}

.landing-main-content-item {
	width: 100%;
	margin: auto;
}

.landing-main-title {
	font-size: 4rem;
	font-weight: 500;
	text-align: center;
	/* width: 400px; */
	margin: 25px auto auto;
}

.landing-main-end-title {
	font-size: 3.5rem;
	font-weight: 500;
	text-align: center;
	/* width: 400px; */
	margin: 25px auto auto;
	max-width: 800px;
}

.landing-main-description {
	font-size: 1.5rem;
	text-align: center;
	width: 500px;
	margin: 15px auto auto;
}

.landing-main-img img {
	width: 100%;
	border: 2px solid white;
	border-radius: 25px;
	margin-top: 75px;
}

.landing-buttons {
	margin-top: 50px;
}

.landing-demo-button {
	padding: 20px 0;
	text-align: center;
	background-color: var(--orange);
	border-radius: 15px;
	justify-content: center;
	width: 200px;
	margin: auto 15px auto auto;
	text-decoration: none;
	color: black
}

.landing-signup-button {
	padding: 20px 0;
	text-align: center;
	/* background-color: yellow; */
	border: 2px solid white;
	border-radius: 15px;
	justify-content: center;
	width: 200px;
	margin: auto auto auto 15px;
	text-decoration: none;
	color: white
}

.landing-end-button {
	padding: 20px 0;
	text-align: center;
	background-color: var(--orange);
	border-radius: 15px;
	justify-content: center;
	width: 200px;
	margin: auto;
	text-decoration: none;
	color: black
}

.landing-image-container {
	margin: auto;
}

.landing-image-container img {
	object-fit: cover;
	width: 100%;
	height: 275px;
	border-radius: 15px;
	background: white;
	padding: 10px;
}

.landing-nav-container {
	width: 100%;
	height: 100px;
	margin-top: 0;
	align-items: center;
	margin-bottom: 25px;
}

.landing-nav-item {
	margin-left: 10px;
	margin-right: 10px;
	cursor: pointer;
}

.landing-nav-item a {
	padding: 10px 25px;
	text-decoration: none;
	color: white;
}

.nav-container {
	background-color: #bfbfbf;
}

.nav-login {
	margin-left: auto;
}

.nav-signup a {
	padding: 15px 35px;
	background-color: var(--orange);
	border-radius: 5px;
	text-decoration: none;
	color: black;
}

.nav-title {
	align-self: center;
}

/* 
.nav-title a {
	font-size: 1.75rem;
	font-weight: 500;
	text-decoration: none;
	color: #fff;
} */

.nav-item-first {
	margin-left: auto;
}

.nav-item {
	align-self: center;
	margin-right: 15px;
}

/* .nav-item:first {
	margin-left: auto;
} */

.nav-item a {
	color: black;
	font-size: .9rem;
	padding: 15px 15px;
	background-color: var(--orange);
	border-radius: 5px;
	text-decoration: none;
	color: black;
}

.logout-container {
	width: 100%;
	align-items: center;
}

.logout-button {
	display: flex;
	background-color: var(--red);
	border-radius: 5px;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	color: #ffffff;
	font-weight: bold;
	padding: 15px 15px;
	width: 100%;
	font-size: .9rem;
}

.back-home-button {
	text-decoration:none;
	cursor:pointer;
	margin: auto;
}

.account-button {
	display: flex;
	background-color: var(--light-blue);
	border-radius: 5px;
	cursor: pointer;
	align-items: center;
	justify-content: center;
	color: #fff;
	font-weight: bold;
	padding: 15px 20px;
	width: 100%;
	white-space: nowrap;
}

.landing-top-break {
	margin: 50px auto 0;
}

.landing-end-hr {
	background-color: white;
	margin: 150px 0 10px;
}

.landing-footer {
	width: 100%;
}

.landing-footer-item {
	text-align: center;
	margin: 25px auto
}

.landing-footer-item a {
	color: white;
	text-decoration: none;
	padding: 15px 25px;

}

/* .landing-top-break hr {
	border: 0;
	clear: both;
	display: block;
	width: 100%;
	height: 1px;
	background-color: black;
	margin: auto 20px;
} */

.landing-top-break-text {
	white-space: nowrap;
}

.landing-main-content {
	height: 100%;
}

.landing-feature-container {
	justify-content: center;
	align-items: center;
	/* height: 500px; */
	margin: 50px auto;
	max-width: 1250px;
}

.landing-feature-item {
	margin: auto;
	width: 90%;
}

.landing-feature-text {
	width: 25%;
}

.landing-feature-image {
	width: 75%;
	height: 100%;
}

.landing-feature-image-container {
	margin: auto;
}

.landing-feature-image-container img {
	width: 100%;
	margin: auto;
	border-radius: 15px;
	border: 2px solid white;
}

.landing-feature-title {
	font-weight: bold;
	margin-bottom: 10px;
	font-size: 3rem;
}

.landing-feature-description {}

.pricing-container {
	background-color: var(--brown);
	color: white;
	width: 80%;
	margin: auto;
}

.pricing-logout-container {
	width: 200px;
	margin-left: auto;
	padding-top: 10px;
}

.pricing-logo {
	width: 100px;
	margin-right: auto;
}

.pricing-logo img {
	width: 100px;
}

.MuiSwitch-track {
	background-color: white !important
}

.MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track {
	background-color: var(--light-blue) !important;
}

.MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked {
	color: var(--light-blue);
}

.MuiButtonBase-root-MuiButton-root {
	background-color: var(--light-blue) !important;
}

.MuiCardHeader-root {
	background-color: var(--orange) !important;
}

/* End Landing Page */

/* Support Page */

.support-page-links-container {
	flex-wrap: wrap;
}

.support-page-title {
	margin: 25px auto;
}

.support-page-subtitle {
	margin: auto;
    width: 60%;
}

.support-page-links-container div {
	/* margin: auto; */
	margin-top: 50px;
	align-self: center;
}

.support-page-link {
	margin: auto;
	justify-content: center;
	padding: 10px;
	border-radius: 5px;
	background: white;
	width: 150px;
	;
}

.support-page-logo {
	width: 150px;
}

/* End Support Page */


@media print {
	.map-container-maps {
		display: table;
		width: 100%;
	}

	.map-svg-container {
		margin: 10px;
		width: 200px;
		height: 200px;
		max-width: 220px;
		display: inline-block;
	}

	.settings-container, .map-expand-round {
		display: none;
	}

	.home {
		margin-left: 0;
	}
}